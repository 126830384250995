import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMobile:null,
  },
  mutations: {
    /**
     * 设备查看，判断是PC还是手机
     */
    handleDeviceCheck(state) {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      // let ucMobile = navigator.userAgent.match(/(uc|uc7)/i);
      if (flag != null) {
        state.isMobile = true;
      } else {
        state.isMobile = false;
      }

    },
  },
  actions: {
  },
  modules: {
  }
})
