import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/views/index',
    name: 'index',
    meta: { title: '首页' },
    component: () =>
    import ('@/views/index.vue')
  },
  {
    path: '/views/about',
    name: 'about',
    meta: { title: '关于我们' },
    component: () =>
    import ('@/views/about/index.vue'),
  },
  {
    path: '/views/news',
    name: 'news',
    meta: { title: '新闻动态' },
    component: () =>
    import ('@/views/news/index.vue')
  },
  {
    path: '/views/contact',
    name: 'contact',
    meta: { title: '联系我们' },
    component: () =>
    import ('@/views/contactme/index.vue')
  },
  {
    path: '/views/programme',
    name: 'programme',
    meta: { title: '解决方案' },
    component: () =>
    import ('@/views/programme/index.vue')
  },
  {
    path: '/views/privacyPolicy',
    name: 'privacyPolicy',
    meta: { title: '隐私政策' },
    component: () =>
      import ('@/views/privacyPolicy/privacyPolicy.vue')
  },
  {
    path: '/views/productCenter',
    name: 'productCenter',
    meta: { title: '产品中心' },
    component: () => import ('@/views/productCenter/productCenter.vue'),
  },
  { path: '/views/productCenter/page1',
    name: 'page1',
    component: () => import ('@/views/productCenter/pages/page1'),
  },
  { path: '/views/productCenter/page2',
    name: 'page2',
    component: () => import ('@/views/productCenter/pages/page2'),
  },
  { path: '/views/productCenter/page3',
    name: 'page3',
    component: () => import ('@/views/productCenter/pages/page3'),
  },
  { path: '/views/productCenter/page4',
    name: 'page4',
    component: () => import ('@/views/productCenter/pages/page4'),
  }

]

const router = new VueRouter({
  base: "/dist/",
  routes
})

export default router
