<!--主页面-->
<template>
  <router-view></router-view>
<!--  <div class="mlfow-home" :style="'min-height:' + minHeight + 'px'">
    &lt;!&ndash;  导航栏  &ndash;&gt;
    &lt;!&ndash; <topHeadBar :isMobile="isMobile" @barBtnVal="monitorBar"></topHeadBar> &ndash;&gt;
    <div id="topHeadBar">
      <div :class="$store.state.isMobile ? '_home-head-bar' : 'home-head-bar'">
        <div class="home-head-bar-left">
          <div class="logoImg">
            <img src="../assets/homePage/mlf-icon-yellow.png" alt="mlf" />
          </div>
        </div>
        <div class="home-head-bar-right">
          <div class="home-bar" :class="$store.state.isMobile ? '_home-bar' : ''">
            <button @click="switchBtn(item.sign,item.url)"  :class="item.sign == btnVal ? ($store.state.isMobile ? 'current_click_mo' : 'current_click_pc'):''" v-for="(item,k) in title_list" :key="k">
              <span>
                {{item.title}}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <bottomFoot :isMobile="$store.state.isMobile"></bottomFoot>
  </div>-->
</template>

<script>
// import bottomFoot from "@/components/footer/index";
export default {
  name: "index",
  mounted() {
    this.$store.commit('handleDeviceCheck',{});
    this.minHeight = window.screen.height;
  },
  components: {
    // bottomFoot,
  },
  data() {
    return {
      location: "首页",
      minHeight: "",
      isActive: "",
      address: "",
      isMobile: null,
      btnVal:'Home',
      title_list:[
        {
          sign:'Home',
          title:'首页',
          url:'/',
        },{
          sign:'about',
          title:'关于麦乐峰',
          url:'/views/about',
        },{
          sign:'productCenter',
          title:'产品中心',
          url:'/views/productCenter',
        },{
          sign:'programme',
          title:'解决方案',
          url:'/views/programme',
        },{
          sign:'news',
          title:'新闻动态',
          url:'/views/news',
        },{
          sign:'contact',
          title:'联系我们',
          url:'/views/contact',
        },
      ]
    };
  },
  watch: {
    $route:{
      handler:function(route){
        this.btnVal = route.name;
      }
    }
  },
  methods: {
    switchBtn(sign,url){
      this.btnVal = sign;
      this.$router.push({path:url})
    },
  },
};
</script>

<style scoped lang="scss">
.mlfow-home {
  position: relative;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
  }
}
#topHeadBar{
  z-index:99;
}
._home-head-bar {
  z-index: 50;
  background-color: rgb(1, 82, 147);
  width: 100vw;
  text-align: center;
  .home-head-bar-right {
    padding: 20px 0;
    display: flex;
    border-bottom: 1px solid #ffffff;
    border-top: 1px solid #ffffff;
    font-size: 1.8rem;
    justify-content: center;
    align-items: center;
    > button {
      flex: 1;
      border: none;
    }
  }
}

.home-head-bar {
  background-color: #015293;
  z-index: 50;
  justify-content: space-between;
  display: flex;
  overflow: hidden;
  padding: 0 11vw 0 10vw;
  > div {
    display: inline-block;
  }

  .home-head-bar-left {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-head-bar-right {
    text-align: right;
    vertical-align: top;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-head-bar-top {
    display: block;
  }

  .home-head-bar-bottom {
    display: block;
  }
}
.home-bar {
  font-size:0.9vw;
  .current_click_pc {
    color: rgb(248, 196, 65);
  }

  .current_click_mo {
    color: rgb(248, 196, 65);

    > span {
      padding-bottom: 1.5vh;
      border-bottom: rgb(248, 196, 65) 2px solid;
    }
  }

  > button:first-child {
    border-left: none;
  }

  > button {
    cursor: pointer;
    border: none;
    background: none;
    color: #ffffff;
    border-left: #ffffff solid 1px;
    width: 7vw;
  }
}
._home-bar {
  display: flex;
  padding: 0.5vh 0.5vw;
  width: 100%;
  font-size: 3vw;
  > button {
    flex: 1;
    border: none;
  }
}
._home-items-title {
  position: relative;
  height: 180px;
  .more_text {
    padding: 0.5vh 0.5vh;
  }
  > div:nth-child(1) {
    font-size: 2.3rem;
    font-weight: bold;
    position: absolute;
    top: 1.5vh;
    left: 0;
    z-index: 50;
  }

  > div:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 49;

    > img {
      width: 280px;
    }
  }

  > div:nth-child(3) {
    position: absolute;
    top: 4vh;
    left: 13%;
    color: rgb(204, 204, 204);
    font-weight: bold;
    letter-spacing: 3px;
  }

  > hr {
    position: absolute;
    right: 0;
    width: calc(100% - 14rem);
    top: 3vh;
    color: rgb(204, 204, 204);
  }

  .more_news {
    font-size: 1.5rem;
    position: absolute;
    right: 0;
    top: 1vh;
    color: #909399;
  }
}
</style>
