<template>
  <div id="app" :style="'min-height:'+ minHeight + 'px'">
    <index></index>
  </div>
</template>

<script>
import index from "./views/index";

export default {
  name: 'App',
  components: {
    index,
  },
  data () {
    return {
      minHeight:'',
    }
  },
  mounted () {
    this.minHeight = window.screen.height
  },
  methods:{
  }
}
</script>

<style>
#app {
  margin: 0;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}
</style>
