<!--主页面-->
<template>
  <div>
<!--    todo 临时-->
    <img src="../assets/images/homeBackground.jpg" style="width: 100%" alt="">
    <div style="width: 6.5%;height: 5%;z-index: 100;position: absolute;top: 1%;left: 61%;cursor: pointer" @click="handleGoProduct"></div>
  </div>
<!--  <div class="mlfow-home" v-if="!isUCMobile">
    &lt;!&ndash;  导航栏  &ndash;&gt;
    &lt;!&ndash; <div :class="isMobile? '_home-head-bar':'home-head-bar'">
      <div class="home-head-bar-left">
        <img src="../assets/homePage/mlf-icon-yellow.png" alt="mlf">
      </div>
      <div class="home-head-bar-right home-bar">
        <button :class="isMobile? 'current_click_mo':'current_click_pc'">
          <span>首页</span>
        </button>
        <button v-if="!isMobile">关于麦乐峰</button>
        <button>
          <span>产品中心</span>
        </button>
        <button>
          <span>解决方案</span>
        </button>
        <button>
          <span>新闻动态</span>
        </button>
        <button v-if="!isMobile">
          <span>联系我们</span>
        </button>
        <button v-if="isMobile">
          <span>关于麦乐峰</span>
        </button>
      </div>
    </div> &ndash;&gt;
    &lt;!&ndash;  背景墙  &ndash;&gt;
    <div class="home-carousel">
      <el-carousel height="50vw" v-if="isMobile">
        <el-carousel-item v-for="(item,index) in mobileImageList" :key="index">
          <img :src="item" alt="" style="width: 100%">
        </el-carousel-item>
      </el-carousel>
      <el-carousel height="50vw" v-else>
        <el-carousel-item v-for="(item,index) in imageList" :key="index">
          <img :src="item" alt="" style="width: 100%">
        </el-carousel-item>
      </el-carousel>
    </div>
    &lt;!&ndash;  产品中心  &ndash;&gt;
    <div :class=" isMobile? '_home-product-center': 'home-product-center'">
      <div :class="isMobile? '_home-items-title': 'home-items-title'">
        <div>产品中心</div>
        <div><img src="../assets/homePage/title-icon.png" alt=""></div>
        <div> PRODUCT CENTER</div>
        <hr v-if="!isMobile">
      </div>
      <div class="home-product-center-list">
        <div class="home-product-center-item" v-for="(item,index) in productList" :key="index">
          <div><img :src="item.imageURL" alt="图片"></div>
          <div>{{ item.title }}</div>
          <div v-if="!isMobile">{{ item.subTitle }}</div>
        </div>
      </div>
    </div>
    &lt;!&ndash;  解决方案  &ndash;&gt;
    <div class="home-business-layout">
      <div v-if="!isMobile" class="home-business-layout-pc">
        &lt;!&ndash;        <img src="../assets/homePage/business_layout.png" alt="">&ndash;&gt;
        <div class="home-items-title">
          <div>解决方案</div>
          <div><img src="../assets/homePage/title-icon.png" alt=""></div>
          <div>BUSINESS LAYOUT</div>
        </div>
        <div>
          <img src="../assets/homePage/layout_pc1.png" alt="">
        </div>
        <div>
          <img src="../assets/homePage/layout_pc2.png" alt="">
        </div>
        <div>
          <img src="../assets/homePage/layout_pc3.png" alt="">
        </div>
        <div>
          <img src="../assets/homePage/layout_pc4.png" alt="">
        </div>
        <div v-for="(item,index) in layoutList" :key="index">
          <div>{{ item.title }}</div>
          <div>{{ item.subTitle }}</div>
        </div>
      </div>
      <div v-else class="home-business-layout-mo">
        <div :class="isMobile? '_home-items-title': 'home-items-title'">
          <div>解决方案</div>
          <div><img src="../assets/homePage/title-icon.png" alt=""></div>
          <div> BUSINESS LAYOUT</div>
        </div>
        <div>
          <div v-for="(item,index) in layoutList" :key="index" class="home-business-layout-item">
            <div>
              <img :src="item.image" alt="">
            </div>
            <div>{{ item.title }}</div>
            <div>{{ item.subTitle }}</div>
          </div>
        </div>
      </div>
    </div>
    &lt;!&ndash;  新闻动态  &ndash;&gt;
    <div :class="isMobile? '_home-news-information':'home-news-information'">
      <div :class="isMobile? '_home-items-title': 'home-items-title'">
        <div>新闻动态</div>
        <div><img src="../assets/homePage/title-icon.png" alt=""></div>
        <div>NEWS INFORMATION</div>
        <hr>
        <div class="more_news">更多></div>
      </div>
      <div class="home-news-information-list">
        <div v-for="(item,index) in newsList" :key="index" class="home-news-information-item">
          <div>
            <div><img :src="item.image" alt="图片"></div>
            <div>
              <div>{{ item.head }}</div>
              <div>{{ item.subHead }}</div>
              <div v-if="isMobile">{{ item.date }}</div>
            </div>
          </div>
          <div v-if="!isMobile">{{ item.date }}</div>
        </div>
      </div>
    </div>
    <bottomFoot :isMobile="$store.state.isMobile"></bottomFoot>
    &lt;!&ndash; <div>
      <div v-if="!isMobile" class="home-footer">
        <div><img src="../assets/homePage/mlf-icon-white.png" alt="logo"></div>
        <div>
          <div class="home-bar" :class="isMobile? '_home-bar':''">
            <button>首页</button>
            <button>关于麦乐峰</button>
            <button>产品中心</button>
            <button>解决方案</button>
            <button>新闻动态</button>
            <button>联系我们</button>
          </div>
          <div>
            Copyright ©2011-2021 麦乐峰(厦门)智能科技有限公司 版权所有 MLF Corparation, All Rights Reserved. 闽ICP备13015613号-1
          </div>
          <div>
            <img src="../assets/homePage/code.png" alt="">
          </div>
        </div>
        <div>
          <div class="home-contact-item">
            <div>地址</div>
            <div>厦门市思明区观音山国际商务营运中心5号楼14楼</div>
          </div>
          <div class="home-contact-item">
            <div>邮编</div>
            <div>361008</div>
          </div>
          <div class="home-contact-item">
            <div>总机</div>
            <div>0592-2577555</div>
          </div>
          <div class="home-contact-item">
            <div>Email</div>
            <div>MLF@mlfworldgroup.com</div>
          </div>
        </div>
      </div>
      <div v-else class="_home-footer">
        <div>
          <div>
            <div>
              <div>Copyright ©2011-2021 麦乐峰(厦门)智能科技有限公司</div>
              <div>版权所有 MLF Corparation, All Rights Reserved. 闽ICP备13015613号-1</div>
            </div>
            <div>
              <img src="../assets/homePage/code.png" alt="">
            </div>
          </div>
          <hr>
          <div>
            <div>
              <div>地址</div>
              <div>厦门市思明区观音山国际商务营运中心5号楼14楼</div>
            </div>
            <div>
              <div>邮编</div>
              <div>361008</div>
            </div>
            <div>
              <div>总机</div>
              <div>0592-2577555</div>
            </div>
            <div>
              <div>Email</div>
              <div>MLF@mlfworldgroup.com</div>
            </div>
          </div>
        </div>
      </div>
    </div> &ndash;&gt;
  </div>
  <div v-else>请使用其他浏览器浏览该网站</div>-->
</template>

<script>
// import bottomFoot from "@/components/footer/index"
export default {
  name: "Home",
  // components:{bottomFoot},
  data() {
    return {
      value1: 0,
      isUCMobile:false,
      productList: [
        {
          imageURL: require("../assets/homePage/product1.png"),
          title: "智慧军营",
          subTitle: "智慧化、现代化营区管理"
        },
        {
          imageURL: require("../assets/homePage/product2.png"),
          title: "军事训练",
          subTitle: "训练考核、分析评估"
        },
        {
          imageURL: require("../assets/homePage/product3.png"),
          title: "强军主题",
          subTitle: "科技强军，科技兴军"
        },
        {
          imageURL: require("../assets/homePage/product4.png"),
          title: "心理作战",
          subTitle: "智慧化、现代化营区管理"
        },
        {
          imageURL: require("../assets/homePage/product5.png"),
          title: "模拟仿真",
          subTitle: "VR虚拟现实，智能打靶"
        },
        {
          imageURL: require("../assets/homePage/product6.png"),
          title: "战场救援",
          subTitle: "提升战士医疗救护能力"
        },
        {
          imageURL: require("../assets/homePage/product7.png"),
          title: "军事大数据",
          subTitle: "数据深度智能处理分析"
        },
        {
          imageURL: require("../assets/homePage/product8.png"),
          title: "训练指导",
          subTitle: "综合体能训练，强军作战能力"
        },
      ],
      imageList: [require("../assets/homePage/active1.jpg")],
      mobileImageList: [require("../assets/homePage/active_mobile1.jpg")],
      newsList: [
        {
          id: '',
          image: require("../assets/homePage/news1.png"),
          head: '公司获得信息技术服务管理体系、信息安全管理体系等2项ISO认证',
          subHead: '公司获得信息技术服务管理体系、信息安全管理体系等2项ISO认证。',
          date: '2020-11-27'
        },
        {
          id: '',
          image: require("../assets/homePage/news2.png"),
          head: '公司通过厦门双软企业认证',
          subHead: '公司通过软件产品登记、软件企业认定审核。',
          date: '2020-09-15'
        },
        {
          id: '',
          image: require("../assets/homePage/news3.png"),
          head: '麦乐峰科技成功挂牌海峡科创板',
          subHead: '麦乐峰科技成功挂牌海峡科创板 (公司简称：麦乐峰，股权代码：160001)。',
          date: '2020-09-02'
        },
        {
          id: '',
          image: require("../assets/homePage/news4.png"),
          head: '公司获得CMMI软件成熟度三级认证',
          subHead: '公司顺利通过CMMI三级认证。',
          date: '2019-12-12'
        },
      ],
      layoutList: [
        {
          image: require("../assets/homePage/layout1.png"),
          title: '智慧军营解决方案',
          subTitle: '全域感知，融合应用，推进部队数字化、高效化、集约化、可视化管理，打造“智慧军营”。'
        },
        {
          image: require("../assets/homePage/layout2.png"),
          title: '军事训练解决方案',
          subTitle: '组训有法，评估有据，为深化训练落实、改进训练手段、提高训练效益提供及时高效的辅助性决策分析手段。'
        },
        {
          image: require("../assets/homePage/layout3.png"),
          title: '强军主题解决方案',
          subTitle: '科技兴军，科技强军，为部队、军院校提供全方位、立体化、信息化的训练教学辅助平台。'
        },
        {
          image: require("../assets/homePage/layout4.png"),
          title: '心理作战解决方案',
          subTitle: '战时环境模拟，心理数据采集，集训练、生理、心理三维为一体，全面提升官兵战场心理适应能力与快速反应能力。'
        }
      ]
    }
  },
  props: {
    isMobile: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleGoProduct(){
      this.$router.push('/views/productCenter')
    }
  }
}
</script>

<style scoped lang="scss">
.mlfow-home {
  position: absolute;
  top:0;
  left:0;
  ._home-bar {
    display: flex;

    > button {
      flex: 1;
    }
  }

  .home-bar {
    .current_click_pc {
      color: rgb(248, 196, 65);
    }

    .current_click_mo {
      color: rgb(248, 196, 65);

      > span {
        padding-bottom: 1.5vh;
        border-bottom: rgb(248, 196, 65) 2px solid;
      }
    }

    > button:first-child {
      border-left: none;
    }

    > button {
      cursor: pointer;
      border: none;
      background: none;
      color: #ffffff;
      border-left: #ffffff solid 1px;
      width: 7vw;
    }
  }

  ._home-items-title {
    position: relative;
    height: 180px;

    > div:nth-child(1) {
      font-size: 2.3rem;
      font-weight: bold;
      position: absolute;
      top: 1.5vh;
      left: 0;
      z-index: 50;
    }

    > div:nth-child(2) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 49;

      > img {
        width: 280px;
      }
    }

    > div:nth-child(3) {
      position: absolute;
      top: 4vh;
      left: 13%;
      color: rgb(204, 204, 204);
      font-weight: bold;
      letter-spacing: 3px;
    }

    > hr {
      position: absolute;
      right: 0;
      width: calc(100% - 14rem);
      top: 3vh;
      color: rgb(204, 204, 204);
    }

    .more_news {
      font-size: 1.5rem;
      position: absolute;
      right: 0;
      top: 2vh;
      color: #909399;
    }
  }

  .home-items-title {
    position: relative;
    height: 15vh;

    > div:nth-child(1) {
      font-size: 2.1rem;
      font-weight: bold;
      position: absolute;
      top: 2.5vh;
      left: 5%;
      z-index: 50;
    }

    > div:nth-child(2) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 49;

      > img {
        width: 14rem;
      }
    }

    > div:nth-child(3) {
      position: absolute;
      top: 8vh;
      left: 7%;
      color: rgb(204, 204, 204);
      font-weight: bold;
      letter-spacing: 3px;
    }

    > hr {
      position: absolute;
      right: 0;
      width: calc(100% - 14rem);
      top: 6vh;
      color: rgb(204, 204, 204);
    }

    .more_news {
      position: absolute;
      right: 0;
      top: 4.5vh;
      color: #909399;
    }
  }

  .home-contact-item {
    font-size: 0.9rem;
    padding: 10px;

    > div:first-child {
      color: #909399;
    }
  }
}

._home-head-bar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  background-color: rgb(1, 82, 147);
  width: 100vw;
  text-align: center;

  .home-head-bar-left {
    padding-top: 10px;
    height: 80px;
    overflow: hidden;
  }

  .home-head-bar-right {
    margin-top: 20px;
    height: 4vh;
    padding: 10px 0;
    display: flex;
    border-bottom: 1px solid #ffffff;
    border-top: 1px solid #ffffff;
    font-size: 1.8rem;

    > button {
      flex: 1;
      border: none;
    }
  }
}

.home-head-bar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  width: 80vw;
  margin: 2vh 10vw;
  display: flex;
  overflow: hidden;

  > div {
    display: inline-block;
  }

  .home-head-bar-left {
    flex: 1;
  }

  .home-head-bar-right {
    margin-top: 2vh;
    flex: 1.2;
    text-align: right;
    vertical-align: top;
  }

  .home-head-bar-top {
    display: block;
  }

  .home-head-bar-bottom {
    display: block;
  }

}

.home-carousel {
  width: 100%;
  height: auto;
}

.home-business-layout {
  width: 100%;

  .home-business-layout-pc {
    background-color: rgb(245, 245, 245);
    height: 60vw;
    position: relative;
    width: 100%;

    > div {
      position: absolute;

      > img {
        width: 100%;
      }
    }

    > div:nth-child(1) {
      left: 38%;
      top: 30%;

      > div:first-child {
        width: 200px;
        top: 4.5rem;
        left: 80px;
      }

      > div:nth-child(2) {
        top: 3rem;
      }

      > div:last-child {
        top: 0;
        font-size: 4rem;
        line-height: 6rem;
        left: 40px;
      }
    }

    > div:nth-child(2) {
      width: 38%;
      top: 10%;
    }

    > div:nth-child(3) {
      width: 13.5%;
      right: 25%;
    }

    > div:nth-child(4) {
      width: 13.9%;
      top: 29%;
      right: 5%;
    }

    > div:nth-child(5) {
      width: 19.7%;
      right: 24%;
      bottom: 0;
    }

    > div:nth-child(n+6) {
      width: 16%;

      > div:first-child {
        font-size: 1.2rem;
        font-weight: bold;
        padding-bottom: 10px;
      }

      > div:last-child {
        line-height: 1.5rem;
      }
    }

    > div:nth-child(6) {
      left: 39%;
      bottom: 20%;
    }

    > div:nth-child(7) {
      top: 5%;
      right: 8%;
    }

    > div:nth-child(8) {
      width: 14%;
      top: 55%;
      right: 5%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
    }

    > div:nth-child(9) {
      bottom: 5%;
      right: 6%;
    }
  }

  .home-business-layout-mo {
    padding: 45px 60px;

    .home-business-layout-item {
      display: inline-block;
      width: calc((100vw - 5vw - 120px) / 2);
      overflow: hidden;

      > div:nth-child(1) {
        height: 250px;
        overflow: hidden;

        > img {
          object-fit: cover;
          width: 100%;
        }
      }

      > div:nth-child(2) {
        font-size: 2rem;
        font-weight: bold;
        padding: 20px 0 0;
      }

      > div:nth-child(3) {
        padding: 30px 0 0;
        line-height: 40px;
        font-size: 1.8rem;
        font-weight: normal;
        color: #888888;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }

    .home-business-layout-item:nth-child(1), .home-business-layout-item:nth-child(2) {
      padding-bottom: 45px;
    }

    .home-business-layout-item:nth-child(2n+1) {
      margin-right: 15px;
    }

    .home-business-layout-item:nth-child(2n) {
      margin-left: 15px;
    }
  }

}

._home-product-center {
  padding: 45px 60px 10px;

  .home-product-center-list {
    .home-product-center-item {
      width: 25%;
      display: inline-block;
      text-align: center;
      padding-bottom: 2vh;

      > div:nth-child(1) {
        width: 100%;
        overflow: hidden;

        > img {
          width: 50%;
        }
      }

      > div:nth-child(2) {
        padding-top: 1vh;
        font-size: 1.5rem;
        font-weight: bold;
      }

      > div:nth-child(3) {
        padding-top: 0.5vh;
        color: rgb(102, 102, 102);
      }
    }
  }
}

.home-product-center {
  padding: 5vh 5vw;

  .home-product-center-list {
    .home-product-center-item {
      width: 25%;
      display: inline-block;
      text-align: center;
      padding-bottom: 10vh;

      > div:nth-child(1) {
        width: 100%;
        overflow: hidden;

        > img {
          width: auto;
        }
      }

      > div:nth-child(2) {
        padding-top: 1vh;
        font-size: 1.2rem;
        font-weight: bold;
      }

      > div:nth-child(3) {
        padding-top: 0.5vh;
        color: rgb(102, 102, 102);
      }
    }
  }
}

._home-news-information {
  padding: 45px 60px;

  .home-news-information-item {
    padding-bottom: 45px;
    margin-left: 3vw;
    width: calc(100% - 3vw);
    border-bottom: 1px dotted rgb(225, 225, 225);

    > div {
      display: inline-block;
      vertical-align: middle;

      > div:nth-child(1) {
        display: inline-block;
        width: 25vw;
        height: 200px;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
        }
      }

      > div:nth-child(2) {
        vertical-align: top;
        display: inline-block;
        width: calc(100% - 25vw);
        position: relative;
        height: 200px;

        > div:nth-child(1) {
          margin: 10px;
          font-size: 2rem;
          font-family: '黑体';
          font-weight: bold;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }

        > div:nth-child(2) {
          font-size: 1.8rem;
          padding: 10px;
          line-height: 40px;
        }

        > div:nth-child(3) {
          font-size: 1.8rem;
          padding: 10px;
          color: rgb(102, 102, 102);
          position: absolute;
          bottom: 0;
        }
      }
    }

  }
}

.home-news-information {
  padding: 0 5vw;

  .home-news-information-item {
    padding: 1.5vh 0;
    margin-left: 3vw;
    width: calc(100% - 3vw);
    border-bottom: 1px dotted rgb(225, 225, 225);

    > div:first-child {
      width: 80%;
      display: inline-block;
      vertical-align: middle;

      > div:nth-child(1) {
        display: inline-block;
        width: 80px;
        height: 80px;
        overflow: hidden;

        > img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
        }
      }

      > div:nth-child(2) {

        vertical-align: top;
        display: inline-block;

        > div:first-child {
          padding: 5px;
          font-size: 1.3rem;
          font-family: '黑体';
          font-weight: bold;
        }

        > div:last-child {
          padding: 5px;
          color: rgb(102, 102, 102);
        }
      }
    }

    > div:last-child {
      width: 20%;
      display: inline-block;
      text-align: right;
    }
  }
}

._home-footer {
  background-color: rgb(1, 82, 147);
  color: #ffffff;

  > div {
    padding: 45px 60px;

    > div:first-child {
      display: flex;
      height: 5vh;
      padding-bottom: 45px;
      > div:nth-child(1) {
        flex: 6;
        font-size: 1.4rem;
        line-height: 2.5vh;
      }

      > div:nth-child(2) {
        flex: 0.8;
        text-align: right;
        >img{
          height: 100%;
        }
      }
    }
    > div:last-child{
      display: flex;
      font-size: 1.4rem;
      padding-top: 45px;
      >div{
        >div:first-child{
          color: #909399;
          padding-bottom: 10px;
        }
      }
      >div:nth-child(1){
        flex: 1.8;
        padding-right: 10px;
      }
      >div:nth-child(2){
        flex: 0.7;
      }
      >div:nth-child(3){
        flex: 1.2;
      }
      >div:nth-child(4){
        flex: 1.6;
      }
    }
  }
}

.home-footer {
  background-color: rgb(1, 82, 147);
  color: #ffffff;
  padding: 5vw;
  display: flex;

  > div:nth-child(1) {
    flex: 2;
  }

  > div:nth-child(2) {
    flex: 6;

    > div {
      text-align: center;
    }

    > div:nth-child(2) {

      padding: 2vh 0;
      font-size: 0.5rem;
    }
  }

  > div:nth-child(3) {
    flex: 2;
  }
}
</style>

