import Vue from 'vue'

import App from './App.vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css'
import '@/assets/css/base.css'
import router from './router'
import store from './store'

// import topHeadBar from './components/head-bar/index.vue'

Vue.use(Element)
Vue.config.productionTip = false

// Vue.component("topHeadBar",topHeadBar)

import VueAMap from 'vue-amap';
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: '03b81d04872a18fb5db2528a8ac4f5b8',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

